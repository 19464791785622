import $, { extend } from 'jquery';
import React from 'react';
import {checkLogin_cBack} from '../function_lib/common_lib';
import {checkUserSession} from '../function_lib/userToken';
import './custom_alert.css';

// Starting of New Method Logic Applying
export const Alert_UserName_Password=(props)=>{
   const loginClick=()=>{
      checkLogin_cBack($("#alert_p1").val(),$("#alert_p2").val(),function(data,msg){
           //alert(data);
           if(data===-10){
            return;
           }

      if(data!=="0" && data!==""){
         let jsData=JSON.parse(data);
         if(jsData.user_info.length===1){
           let datat={};
           datat["user"]=[{"id":jsData.user_info[0]["id"],
             "app_user_id":jsData.user_info[0]["id"],
             "first_name":jsData.user_info[0]["contact_person"],
             "last_name":"",
             "type":"1"
           }];
           //alert(jsData.user_info[0]["authority"]);
           //datat["role"]=[{"col1":"user,master"},{"col2":btoa(jsData.user_info[0]["access_location"])}];
             datat["role"]=[{"col1":btoa(jsData.user_info[0]["authority"])},{"col2":btoa(jsData.user_info[0]["access_location"])}];
           
           
           $("#respSSL_alert").html("Login Successfully..");
     checkUserSession(true);
     localStorage.setItem('userData',JSON.stringify(datat));

     var confirmBox = $("#confirm_new");
     // confirmBox.find(".yes").click();
     $('.coverbg_new').css('display','none');
     confirmBox.hide();

         }else{
            $(".respSSL_alert").html("Invalid UserName or Password !");
         }
       }
      });
   }
   return(<div class="coverbg_new" style={{"background-color":"rgba(212, 194, 194, 0.5)","width":"100%","height":"100vh","background-position":"center","background-size":"cover"}}>
    <div id="confirm_new">
       <div class="message alert_title"><b>{"Title"}</b>
           <span>
           <i class="icondesign fa fa-times" aria-hidden="true"></i>			       
        </span>
  
       </div><br></br>
          <div id="formAlertdiv">
          <form id="frmAlertLogin" name="frmAlertLogin" action="#">
                          <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                    <input type="hidden" name="curl_type" id="curl_type" value="logint" />
                    <input type="hidden" name="m_type" id="m_type" value="logint" />
                    {/* <div className="form-group">
                             
                              <input type="text" className="form-control" id="txtCompanyCode" name="txtCompanyCode" placeholder="Company Code.." />
                            </div> */}
                            <label for="fyear" class="flabel alert_body">{"Body"}</label> <br></br>  

                            <div className="form-group">
                              <input type="text" className="form-control required" id="alert_p1" name="alert_p1" placeholder="UserName.." />
                            </div>
                            <div className="form-group">
                              <input type="password" className="form-control required keyEvent_btn" rf="#btnLogin" id="alert_p2" name="alert_p2" placeholder="Password.." />
                            </div>
                            <div class="col-12 respSSL_alert"></div>
                            <div className="form-group">
                  <button type="button" id="btnAlertLogin" name="btnAlertLogin" onClick={()=>loginClick()} className="btn _btnSetColor123 btn-sm float-left btn-success">Submit</button>   <a className="font-small float-right float-right text-cyan" href="" >Forgot Password ?</a>
                            </div>
                            </form>
          </div>
       </div>
  </div>);
}


export const Custom_Alert_New=(title,body,isRefresh=true,redirectUrl="")=>{
  
   $(".coverbg_new").show();
   var confirmBox = $("#confirm_new");
   confirmBox.find(".alert_title").text(title);     
   confirmBox.find(".alert_body").html(body);
        
        confirmBox.find(".yes").unbind().click(function() {
           confirmBox.hide();
           if(isRefresh){
              if(redirectUrl===""){
                 window.location.reload();
              }else{
                 window.location.href=redirectUrl
              }
              

           }
           
        });
        //confirmBox.find(".yes").click();
        confirmBox.show();
}


//== End of new method local apply

export const Alert_Content=()=>{
    return(<div class="coverbg" style={{"background-color":"rgba(212, 194, 194, 0.5)","width":"100%","height":"1000px","background-position":"center","background-size":"cover"}}>
    <div id="confirm">
       <div class="message alert_title"><b>{"Title"}</b>
           <span>
           <i class="icondesign fa fa-times" aria-hidden="true"></i>			       
        </span>
  
       </div><br></br>
          <div id="formdiv">
              <form method="post">
             <label for="fyear" class="flabel alert_body">{"Body"}</label> <br></br>            
              <input class="butn yes" onClick={()=>closeCusAlert()} type="button" name="send" id="submit" value="Ok" />
              </form>
          </div>
       </div>
  </div>);
 
}
export const closeCusAlert=()=>{
   var confirmBox = $("#confirm");
   // confirmBox.find(".yes").click();
   $('.coverbg').css('display','none');
   confirmBox.hide();
}

export const Custom_Alert_WithInput=(title,body,isRefresh=true,redirectUrl="")=>{
   $(".coverbg").show();
   var confirmBox = $("#confirm");
   confirmBox.find(".alert_title").text(title);     
   confirmBox.find(".alert_body").html(body);
        
        confirmBox.find(".yes").unbind().click(function() {
           confirmBox.hide();
           if(isRefresh){
              if(redirectUrl===""){
                 window.location.reload();
              }else{
                 window.location.href=redirectUrl
              }
              

           }
           
        });
        //confirmBox.find(".yes").click();
        confirmBox.show();
}

export const Custom_Alert=(title,body,isRefresh=true,redirectUrl="")=>{
  
    $(".coverbg").show();
    var confirmBox = $("#confirm");
    confirmBox.find(".alert_title").text(title);     
    confirmBox.find(".alert_body").html(body);
         
         confirmBox.find(".yes").unbind().click(function() {
            confirmBox.hide();
            if(isRefresh){
               if(redirectUrl===""){
                  window.location.reload();
               }else{
                  window.location.href=redirectUrl
               }
               

            }
            
         });
         //confirmBox.find(".yes").click();
         confirmBox.show();
}
export default Custom_Alert;