import { Link,useParams,useNavigate,useLocation } from 'react-router-dom';
import {Custom_Alert_New} from '../components/custom_alert';
export function getUserObjData(){
  var cObject="";
  if(localStorage.getItem("userData")!=null){
    var tmpObject=JSON.parse("{\"access\":"+localStorage.getItem("userData")+"}");
    cObject=tmpObject.access['user'];
  }
  return cObject;
}

export function getURILocation(){
  let uriFull=window.location.href;
  //alert(uriFull);
  let firstIndex=uriFull.lastIndexOf("/");
  let uriName=uriFull;
  if(firstIndex!==-1){
    uriName=uriFull.substring(firstIndex+1);
  }
  
  return uriName;
}

export function checkUserSession(setNew){
  let checkLocation=getURILocation();
  //let checkLocation=useParams();
  if(checkLocation===""){
    localStorage.clear();
    setNew=true;
  }

  const expirationTime = 10 * 60 * 1000; // 10 minutes in milliseconds
  //const expirationTime =  30 * 1000; // 10 minutes in milliseconds
  let _response=true;
  if(setNew===false){
    if(!localStorage.getItem("getSession"))
      {
        _response=false;
        localStorage.clear();
        
        if(checkLocation!==""){
          Custom_Alert_New("Session Expired","Login Session Expired , Kindly Login again",false);
         // window.location.href="../";
        }
        

      }else{
         let getLoginTime=atob(localStorage.getItem("getSession"));
         const now = Date.now();
         if(getLoginTime && (now-getLoginTime>expirationTime)){

          //localStorage.clear();
          if(checkLocation!==""){
            Custom_Alert_New("Session Expired","Login Session Expired , Kindly Login again",false);
            //window.location.href="../";
          }
          
          _response=false;

         }else{
          localStorage.setItem("getSession",btoa(now));
          _response=true;
         }

      }
  }else if(setNew===true){
    let loginTime=Date.now();
    localStorage.setItem("getSession",btoa(loginTime));
    _response=true;
  }

  return _response;

}

// export function getAccessRoleNew(){
//   let cObject="";
//   if(localStorage.getItem("userData")!=null){
//     let tmpObject=JSON.parse(localStorage.getItem("userData"));
//     cObject=tmpObject.role;
//   }
//   return cObject;
// }
export const getUserModuleList=()=>{
  let accessList="";
if(localStorage.getItem("userData")!=null){
  let tmpObject=JSON.parse(localStorage.getItem("userData"));
  let cObject=tmpObject.role;
  accessList=cObject[0]["col2"];
}
return accessList;
}
export function getAccessFunctions(){
  let accessObj={}
if(localStorage.getItem("userData")!=null){
  let tmpObject=JSON.parse(localStorage.getItem("userData"));
  let cObject=tmpObject.role;
 
  let access=cObject[0]["col1"];
  //alert(access);
  if(access.trim()!==""){

    let arrAccess=atob(access).split(',');
    for(let a=0;a<arrAccess.length;a++){
      if(arrAccess[a]==="edit"){
        accessObj["can_edit"]=true;
      }
      if(arrAccess[a]==="delete"){
        accessObj["can_delete"]=true;
      }
      if(arrAccess[a]==="accessibility"){
        accessObj["can_user_access"]=true;
      }
      if(arrAccess[a]==="access_log"){
        accessObj["access_log"]=true;
      }
    }
  }
  
}
return accessObj;
}

var UserProfile = (function() {
    let userObject="";
    let accessRoles="";  
    var getUserObject = function() {
      var cObject=userObject;
      if(cObject!=""){
       var tmpObject=JSON.parse("{\"access\":"+userObject+"}");
        cObject=tmpObject.access['user'];
        //alert(cObject.length);
      }
      return cObject;    // Or pull this from cookie/localStorage
    };

    var getAccessRoles=function(){
      var cObject=userObject;
      if(cObject!=""){
       var tmpObject=JSON.parse("{\"access\":"+userObject+"}");
        cObject=tmpObject.access['role'];
        //alert(cObject.length);
      }
      return cObject;    // Or pull this from cookie/localStorage
    };
  
    var setUserObject = function(userObj) {
        userObject = userObj;     
      // Also set this in cookie/localStorage
    };
  
    return {
        getUserObject: getUserObject,
        setUserObject: setUserObject,
        getAccessRoles:getAccessRoles
       
    }
  
  })();
  
  export default UserProfile;